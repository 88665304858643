@import url('https://fonts.googleapis.com/css?family=Arsenal:400,700');
$primary: #562DC4; /* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

.flash {
	display:none;
}
body {
	font-family: 'Arsenal', sans-serif;
	background: $primary; /* Old browsers */
}
.top-pad {
	color: darken($blk, 25%);
	font-size: 1.3em;
}
.main-nav, .main {
  position: relative;
}
.main-nav {
  background: #fff;
  box-shadow: 0 0px 20px rgba(0,0,0,.4);
}
.main-nav-scrolled {
  position: fixed;
  width: 100%;
  top: 0;
}
.main {
  background: #f2f2e8;
  padding: 110px 50px 50px;
	border-radius: 0;
  column-count: 2;
  column-gap: 40px;
}

nav {
	z-index: 1000;
	// background: transparent;
}
.navbar-default {
	background: $wht;
	border:0;
}
.is-sticky  {
	background-color: red;
}

.navbar .navbar-nav {
	margin: 0px -15px;
	
    > li > a {
		text-align: center;
		height: 100px;
		display: flex;
		align-items: center;
		color: $primary;
		outline: 0;
		padding: 15px;
		@media (max-width: 767px) {
		    height: 30px;
		    display: inline-block;
		    font-size: 15px;
		    color: $wht;
		    padding: 6px;
		}

	    &:hover {
	    	background: darken($primary, 20%);
	    	color: $wht;
	    	outline: 0;
	    }
	    &:active, &:focus {
	    	outline: 0;
	    	color: $primary;
	    	background: transparent;
	    	@media (max-width: 767px) {
	    		outline: 0;
	    		color: $wht;
	    		background: transparent;
	    	}
	    }
	}
}

.navbar-right {
	margin-top: 0px;
		@media(max-width: 767px){
			background: darken($primary, 10%);
		}
}

.navbar-toggle {
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $primary;

		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	input#username, input#password {
		border: 1px solid $primary;
		@media (max-width: 767px) {
			font-size: 16px;
		}
	}
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
		&:focus, &:active {
			background: transparent;
			color: $wht;
		}
	}

	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/
// start sections
section {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	@media(max-width:1024px){
		background-attachment: scroll;
	}
}
.section-a {
	background-image: url('../img/bg1.jpg');
	background-position: 50% 10%;
	padding: 150px 0;
	color: $wht;
	text-align: center;
	h1{
		font-size: 3em;
	}
	p{
		font-size: 2em;
		@media(max-width: 767px){
			font-size: 1.4em;
		}
	}
	@media (max-width: 991px) {
		background-position: 80% 50%;
	}
	@media(max-width: 767px){
		padding: 100px 0;
		background-position: 100% 50%;
	}
	@media (max-width: 500px) {
		background-position: 100% 50%;
	}
	@media (max-width: 400px) {
		background-position: 95% 50%;
		padding: 50px 0;
	}
	@media (max-width: 350px) {
		background-position: 93% 50%;
		padding: 50px 0;
	}
	@media (max-width: 320px) {
		background-position: 91% 50%;
	}
}
.section-b {
	padding-bottom:  100px;
	color: white;
	@media (max-width: 767px) {
		padding-bottom: 50px;
	}

}
.section-c {
	background-image: url('../img/bg2.jpg');
	padding: 250px 0;
	color: $blk;
	text-align: center;
	h1{
		font-size: 3em;
	}
	p{
		font-size: 2em;
		@media(max-width: 767px){
			font-size: 1.5em;
		}
	}
	.cta-box {
		margin-top: 60px;
		a {
			padding: 25px 35px;
			font-size: 1.4em;
			color: $wht;
			text-decoration: none;
			background:$primary;
			outline: 0;
			@media (max-width: 991px) {
				font-size: 1.2em;
			}
			@media (max-width: 767px) {
				font-size: 1em;
			}
			&:hover{
				background: $wht;
				color: $primary;
				outline: 0;
			}
			&:focus, &:active {
				background:$primary;
				outline: 0;
				color: $wht;
			}
		}
	}
	@media (max-width: 991px) {
		padding: 100px 0;
	}
	@media(max-width: 767px){
		padding: 70px 0;
	}
	@media (max-width: 500px) {
		background-position: 52% 50%;
	}
}
// end sections

footer {
	padding: 30px 0px 20px;
	// background: $primary;
	border-top: 1px solid $wht;
	color: $footerLinks;

	a {
		color: $footerLinks;
		outline: 0;
		text-decoration: none;
		white-space: nowrap;

		&:hover {
			color: lighten($footerLinks, 10%);
			outline: 0;
			text-decoration: none;
		}
		&:active, &:focus {
			color: $footerLinks;
			outline: 0;
			text-decoration: none;
		}
	}
}

input#username, input#password {
	width: 100%;
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

.logo {
	max-width: 250px;
	width: 100%;
	@media (max-width: 767px) {
		max-width: 150px;
	}
}

.circle {
	border-radius: 50%;
	max-width: 500px;
	width: 100%;
	margin-top: 50px;
	@media (max-width: 991px) {
		margin-top: 0;
		max-width: 300px;
		width: 100%;
	}
}

.btmTxt {
	h1,p {
		color: white;
	}
	p {
		@media (max-width: 767px) {
			font-size: 1.4em;
		}
	}
	h1 {
		@media (max-width: 767px) {
			font-size: 2em;
		}
	}

}
.bulb {
	max-width: 400px;
	width: 100%;
}

.section-d {
	color: white;
	padding: 100px 0;
	@media (max-width: 991px) {
		padding: 50px 0;
	}
	h1 {
		font-size: 4em;
		@media (max-width: 991px) {
			font-size: 3em;
		}
		@media (max-width: 767px) {
			font-size: 2.5em;
		}
	}
	p {
		font-size: 2em;
		@media (max-width: 767px) {
			font-size: 1.4em;
		}
	}
}

.modal-open {
  overflow: auto; 
  padding-right: 0px !important;
}
.privacyTerms {
	background: white;
}